import { ProductCategoryItem } from 'api/Products/types';
import usePageWithSidebarContent from 'app/hooks/usePageWithSidebarContent';
import { catalogUrls } from 'app/routing';
import { BreadcrumbItem, Page } from 'app/types/ProductCatalog';
import Button from 'components/Button/Button';
import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageWithSidebar from 'components/PageWithSidebar/PageWithSidebar';
import StrataRiskPRS from 'pages/ProductCatalog/components/Product/components/StrataRiskPRS/StrataRiskPRS';
import NextGenerationSequencing from 'pages/ProductCatalog/components/Product/components/NextGenerationSequencing/NextGenerationSequencing';
import useProductCategories from 'pages/ProductCatalog/hooks/useProductCategories';

import React, { useEffect, useState, useMemo } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const emptyProductCategoryItem: ProductCategoryItem = {
  meta: { slug: '', locale: 'en' },
  title: '',
  categoryName: null,
  categoryDetails: [ {
    type: '',
    value: {
      detailsImage: '',
      detailsInfo: '',
    },
    id: '',
  } ],
  primaryButtonText: '',
  secondaryButtonText: '',
  genesAmount: 0,
};

const Product: React.FC<Page> = ({ setPageHeader }) => {
  const navigate = useNavigate();
  const { productSlug } = useParams<{ productSlug: string }>();
  const [ product, setProduct ] = useState<ProductCategoryItem>(emptyProductCategoryItem);
  const { fetchProductCategories, productCategories, isLoading } = useProductCategories();
  const { title, categoryName, secondaryButtonText } = product;
  const isPrs = categoryName === 'PRS';

  useEffect(() => {
    const abortController = new AbortController();
    fetchProductCategories(abortController);

    return () => {
      abortController.abort();
    };
  }, [ fetchProductCategories ]);

  useEffect(() => {
    if (productCategories.items.length > 0) {
      const product = productCategories.items.filter(product => product.meta.slug === productSlug)[0];

      if (product) {
        setProduct(product);
      } else {
        navigate('/');
      }
    }
  }, [ productCategories, productSlug, navigate ]);

  const breadcrumbItems: BreadcrumbItem[] = useMemo(() => [
    { text: 'Products', link: catalogUrls.products },
    { text: title },
  ], [ title ]);

  const firstMenuText = isPrs ? 'What is a PRS?' : 'Genome vs Exome';
  const secondMenuText = isPrs ? 'Clinical advantages' : 'Key Factors';
  const thirdMenuText = isPrs ? 'How Galatea does it?' : 'Analysis Comparing';

  const sectionTitles = useMemo(
    () => [ firstMenuText, secondMenuText, thirdMenuText ],
    [ firstMenuText, secondMenuText, thirdMenuText ],
  );
  const { sidebarItems, sectionItems } = usePageWithSidebarContent(sectionTitles);

  const TestingCatalogButton = useMemo(() => (
    <Link to={catalogUrls.panelsWithSort(categoryName)}>
      <Button size="L" type="ghost">{secondaryButtonText}</Button>
    </Link>
  ), [ categoryName, secondaryButtonText ]);

  useEffect(() => {
    if (isLoading) {
      setPageHeader(undefined);
    } else {
      setPageHeader(<PageHeader title={title} sidebarItems={sidebarItems} breadcrumbItems={breadcrumbItems} rightColumn={TestingCatalogButton} />);
    }
  }, [ setPageHeader, breadcrumbItems, TestingCatalogButton, sidebarItems, title, isLoading ]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet title={title} />
      <PageWithSidebar sidebarItems={sidebarItems}>
        {isPrs ? <StrataRiskPRS sectionItems={sectionItems} /> : <NextGenerationSequencing sectionItems={sectionItems} />}
      </PageWithSidebar>
    </>
  );
};

export default Product;
