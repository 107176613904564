import 'pages/ProductCatalog/components/Panel/components/PanelContent/PanelContent.scss';
import Button from 'components/Button/Button';
import CardiometabolicConditions from 'components/CardiometabolicConditions/CardiometabolicConditions';
import RightSidebar from 'components/RightSidebar/RightSidebar';
import { Body, Heading } from 'components/Typography';
import Specifications from 'pages/ProductCatalog/components/Panel/components/Specifications/Specifications';
import { PanelPage } from 'pages/ProductCatalog/components/Panel/components/PanelContent/types';
import PersonalizedReport from 'pages/ProductCatalog/components/Panel/components/PersonalizedReport/PersonalizedReport';

import React, { useState } from 'react';

const PRSPanelContent: React.FC<PanelPage> = ({ sectionItems, panel }) => {
  const {
    description,
    specifications,
    legalDisclaimer,
    ancestryInformation,
    methodologies,
  } = panel;

  const [ isSidebarOpened, setIsSidebarOpened ] = useState<boolean>(false);
  const openSidebar = () => setIsSidebarOpened(true);
  const closeSidebar = () => setIsSidebarOpened(false);

  return (
    <div className="panelContent">

      <div id={sectionItems[0].id} ref={sectionItems[0].ref} className="panelContent__section">
        <Heading variant="H4">Description</Heading>

        <Body variant="M" style={{ whiteSpace: 'pre-line' }}>{description}</Body>
      </div>

      <div id={sectionItems[1].id} ref={sectionItems[1].ref} className="panelContent__section">
        <Heading variant="H4">Specifications</Heading>
        <Specifications specifications={specifications} methodologies={methodologies} />
      </div>

      <div id={sectionItems[2].id} ref={sectionItems[2].ref} className="panelContent__section">
        <Heading variant="H4">Estimate Cardiometabolic PRS</Heading>

        <Heading variant="H5">StrataRisk™ Cardiometabolic Traits</Heading>

        <div className="panelContent__conditions">
          <CardiometabolicConditions />
        </div>

        <PersonalizedReport />

        {/* We are in the process of redesigning the PRS report, so the team would rather delete/hide these for now */}
        {/* {sampleReport && ( */}
        {/*   <div className="panelContent__sampleReport"> */}
        {/*     <DownloadFile */}
        {/*       document={{ */}
        {/*         title: 'Sample report', */}
        {/*         description: 'Download Sample report', */}
        {/*         documentUrl: sampleReport, */}
        {/*         documentType: 'GuidesDocs', */}
        {/*       }} */}
        {/*     /> */}
        {/*   </div> */}
        {/* )} */}
      </div>

      <div id={sectionItems[3].id} ref={sectionItems[3].ref} className="panelContent__section">
        <Heading variant="H4">Precision and Ancestry</Heading>

        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: ancestryInformation }} />
      </div>

      {legalDisclaimer && (
        <div className="panelContent__section">
          <Heading variant="H4">Legal disclaimer</Heading>

          <Button onClick={openSidebar} type="ghost" size="L">Show details</Button>
        </div>
      )}

      {legalDisclaimer && (
        <RightSidebar title="Legal disclaimer" content={<Body variant="S">{legalDisclaimer}</Body>} opened={isSidebarOpened} onClose={closeSidebar} />
      )}

    </div>
  );
};

export default PRSPanelContent;
