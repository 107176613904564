import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { catalogUrls, galateaDifferenceUrls, patientsUrls, providersUrls } from 'app/routing';
import { ReactComponent as CloseButton } from 'components/Header/assets/CloseButton.svg';
import Logo from 'components/Header/components/Logo/Logo';
import 'components/Header/components/MobileMenu/MobileMenu.scss';
import { Body, Caption } from 'components/Typography';

import cn from 'classnames';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface Props {
  opened: boolean;
  onClose: () => void;
}

const MobileMenu: React.FC<Props> = ({ opened, onClose }) => {
  const { t } = useTranslation();
  const isProviders = !useIsPatientVersion();
  const targetActionLink = isProviders ? providersUrls.orderTest : patientsUrls.submitSample;
  const targetActionLinkText = isProviders ? t('general.orderTest') : t('general.submitSample');
  const howItWorksLink = isProviders ? providersUrls.howItWorks : patientsUrls.howItWorks;
  const resourcesLink = isProviders ? providersUrls.resources : patientsUrls.faq;

  useEffect(() => {
    if (opened) {
      document.querySelector('body')?.style.setProperty('overflow', 'hidden');
    } else {
      document.querySelector('body')?.style.setProperty('overflow', 'auto');
    }
  }, [ opened ]);

  return (
    <div className={cn('mobileMenu', { mobileMenu_opened: opened })} data-testid="mobile-menu">

      <div className="mobileMenu__title">
        <Logo onClick={onClose} />

        <CloseButton onClick={onClose} className={cn('mobileMenu__close', { mobileMenu__close_patients: !isProviders })} data-testid="mobile-menu-close" />
      </div>

      <div className="mobileMenu__content">

        <Link className="mobileMenu__link" to={catalogUrls.products} onClick={onClose}>
          <Body variant="L">{t('menuItems.productCatalog')}</Body>
        </Link>

        <div className={cn('mobileMenu__submenu', { mobileMenu__submenu_patients: !isProviders })}>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={catalogUrls.panels} onClick={onClose}>
            <Caption variant="L">Complete Testing Catalog</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={catalogUrls.panelsWithSort('NGS')} onClick={onClose}>
            <Caption variant="L">Next Generation Sequencing</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={catalogUrls.panelsWithSort('PRS')} onClick={onClose}>
            <Caption variant="L">StrataRisk™ PRS</Caption>
          </Link>
        </div>

        <Link className="mobileMenu__link" to={howItWorksLink} onClick={onClose}>
          <Body variant="L">{t('menuItems.howItWorks')}</Body>
        </Link>

        <Link className="mobileMenu__link" to={targetActionLink} onClick={onClose}>
          <Body variant="L">{targetActionLinkText}</Body>
        </Link>

        <Link className="mobileMenu__link" to={galateaDifferenceUrls.galateaDifference} onClick={onClose}>
          <Body variant="L">{t('menuItems.galateaDifference')}</Body>
        </Link>

        <div className={cn('mobileMenu__submenu', { mobileMenu__submenu_patients: !isProviders })}>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.leadership} onClick={onClose}>
            <Caption variant="L">{t('menuItems.leadershipTeam')}</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.publications} onClick={onClose}>
            <Caption variant="L">{t('menuItems.publications')}</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.events} onClick={onClose}>
            <Caption variant="L">{t('menuItems.events')}</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.news} onClick={onClose}>
            <Caption variant="L">{t('menuItems.news')}</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.compliance} onClick={onClose}>
            <Caption variant="L">{t('menuItems.compliance')}</Caption>
          </Link>
          <Link className="mobileMenu__link mobileMenu__link_sub" to={galateaDifferenceUrls.licenses} onClick={onClose}>
            <Caption variant="L">{t('menuItems.licenses')}</Caption>
          </Link>
        </div>

        <Link className="mobileMenu__link" to={resourcesLink} onClick={onClose}>
          <Body variant="L">{t('menuItems.resources')}</Body>
        </Link>

      </div>
    </div>
  );
};

export default MobileMenu;
