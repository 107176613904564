import styles from 'pages/ProductCatalog/components/Product/components/StrataRiskPRS/components/aaPRSItem/aaPRSItem.module.scss';
import { renderIcon } from 'app/helpers/icons';
import { Body, Heading } from 'components/Typography';

import React from 'react';

interface Props {
  icon: string;
  title: string;
  text: string;
  backgroundColor: string;
}

const AaPRSItem: React.FC<Props> = ({ icon, text, title, backgroundColor }) => {
  return (
    <div className={styles.aaPRSItem} style={{ backgroundColor }}>

      <div className={styles.aaPRSItem__icon}><img src={icon} alt={title} /></div>

      <div>
        <Heading variant="H5">{title}</Heading>

        <Body variant="S">{text}</Body>
      </div>

      {renderIcon('arrow', styles.aaPRSItem__arrow)}

    </div>
  );
};

export default AaPRSItem;
