import 'components/Header/components/TopMenu/TopMenu.scss';
import useIsPatientVersion from 'app/hooks/useIsPatientVersion';
import { catalogUrls, galateaDifferenceUrls, patientsUrls, providersUrls } from 'app/routing';
import { Caption } from 'components/Typography';

import React, { MouseEvent, FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

const TopMenu: React.FC = () => {
  const { t } = useTranslation();
  const isProviders = !useIsPatientVersion();
  const targetActionLink = isProviders ? providersUrls.orderTest : patientsUrls.submitSample;
  const targetActionLinkWidth = isProviders ? 72 : 104;
  const targetActionLinkText = isProviders ? t('general.orderTest') : t('general.submitSample');
  const howItWorksLink = isProviders ? providersUrls.howItWorks : patientsUrls.howItWorks;
  const resourcesLink = isProviders ? providersUrls.resources : patientsUrls.faq;

  const onMouseOver = (e: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
    e.currentTarget.classList.add('topMenu__item_hovered');
    const wrapper = document.querySelector('.headerWrapper');
    if (wrapper) {
      wrapper.classList.add('headerWrapper_white');
    }
  };

  const onMouseOut = (e: MouseEvent<HTMLDivElement> | FocusEvent<HTMLDivElement>) => {
    e.currentTarget.classList.remove('topMenu__item_hovered');
    const wrapper = document.querySelector('.headerWrapper');
    if (wrapper) {
      wrapper.classList.remove('headerWrapper_white');
    }
  };

  const onMenuLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const item = e.currentTarget.parentElement;
    const wrapper = document.querySelector('.headerWrapper');
    if (item && wrapper) {
      item.classList.remove('topMenu__item_hovered');
      wrapper.classList.remove('headerWrapper_white');
    }
  };

  const onSubmenuLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const submenu = e.currentTarget.parentElement;
    if (submenu) {
      submenu.parentElement!.classList.remove('topMenu__item_hovered');
    }
  };

  return (
    <div className="topMenu">

      <div className="topMenu__item" onMouseOver={onMouseOver} onMouseOut={onMouseOut} onFocus={onMouseOver} onBlur={onMouseOut}>
        <NavLink className="topMenu__link" to={catalogUrls.products} onClick={onMenuLinkClick}>
          <Caption variant="L">{t('menuItems.productCatalog')}</Caption>
        </NavLink>

        <div className="topMenu__submenu">
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={catalogUrls.panels}>
            <Caption variant="L">Complete Testing Catalog</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={catalogUrls.panelsWithSort('NGS')}>
            <Caption variant="L">Next Generation Sequencing</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={catalogUrls.panelsWithSort('PRS')}>
            <Caption variant="L">StrataRisk™ PRS</Caption>
          </Link>
        </div>
      </div>

      <div className="topMenu__item">
        <NavLink className="topMenu__link" to={howItWorksLink}>
          <Caption variant="L">{t('menuItems.howItWorks')}</Caption>
        </NavLink>
      </div>

      <div className="topMenu__item">
        <NavLink className="topMenu__link" to={targetActionLink} style={{ width: targetActionLinkWidth }}>
          <Caption variant="L">{targetActionLinkText}</Caption>
        </NavLink>
      </div>

      <div className="topMenu__item" onMouseOver={onMouseOver} onMouseOut={onMouseOut} onFocus={onMouseOver} onBlur={onMouseOut}>
        <NavLink className="topMenu__link" to={galateaDifferenceUrls.galateaDifference} onClick={onMenuLinkClick}>
          <Caption variant="L">{t('menuItems.galateaDifference')}</Caption>
        </NavLink>

        <div className="topMenu__submenu">
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.leadership}>
            <Caption variant="L">{t('menuItems.leadershipTeam')}</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.publications}>
            <Caption variant="L">{t('menuItems.publications')}</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.events}>
            <Caption variant="L">{t('menuItems.events')}</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.news}>
            <Caption variant="L">{t('menuItems.news')}</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.compliance}>
            <Caption variant="L">{t('menuItems.compliance')}</Caption>
          </Link>
          <Link className="topMenu__link topMenu__link_sub" onClick={onSubmenuLinkClick} to={galateaDifferenceUrls.licenses}>
            <Caption variant="L">{t('menuItems.licenses')}</Caption>
          </Link>
        </div>
      </div>

      <div className="topMenu__item">
        <NavLink className="topMenu__link" to={resourcesLink}>
          <Caption variant="L">{t('menuItems.resources')}</Caption>
        </NavLink>
      </div>

    </div>
  );
};

export default TopMenu;
