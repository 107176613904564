import 'pages/WelcomePage/WelcomePage.scss';
import { grayscale400 } from 'app/consts/colors';
import { catalogUrls } from 'app/routing';
import { Page } from 'app/types/ProductCatalog';
import {
  About,
  Publications,
  News,
  Action,
  FullTestsCatalog,
  LookingForSection,
  AncestryAwareSolutions,
  RelentlessCommitment,
} from 'pages/WelcomePage/components';
import { Body, Caption, Hero } from 'components/Typography';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

const WelcomePage: React.FC<Page> = ({ setPageHeader }) => {
  const { t } = useTranslation();

  useEffect(() => {
    setPageHeader(undefined);
  }, [ setPageHeader ]);

  return (
    <>
      <Helmet title="Clinical Genetic Testing" />

      <div className="welcomePage__title">
        <Hero variant="L">{t('welcomePage.precisionHealth')}</Hero>
        <Body variant="L" style={{ marginTop: 22 }}>{t('welcomePage.reimagined')}</Body>
      </div>

      <Caption variant="L" style={{ textTransform: 'uppercase', color: grayscale400 }}>{t('welcomePage.ourProducts')}</Caption>

      <div className="welcomePage__ourProducts">

        <FullTestsCatalog />

        <div className="welcomePage__actions">
          <Action title={t('general.ngs')} text={t('welcomePage.increaseDiagnostic')} link={catalogUrls.nextGenerationSequencing} type="NGS" />

          <Action title={t('general.strataRisk')} text={t('welcomePage.accessMore')} link={catalogUrls.strataRisk} type="PRS" />
        </div>

        <LookingForSection />

      </div>

      <AncestryAwareSolutions />

      <RelentlessCommitment />

      <div className="welcomePage__company">
        <Caption variant="L" style={{ textTransform: 'uppercase', color: grayscale400 }}>{t('welcomePage.company')}</Caption>

        <div className="welcomePage__about">

          <About />

          <div className="welcomePage__publications">
            <Publications />

            <News />
          </div>

        </div>

      </div>
    </>
  );
};

export default WelcomePage;
